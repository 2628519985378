import React, { useState, useEffect } from "react";
import LeftSlider from "../../Inc/LeftSlider";
import { axiosInstance } from "../../axiosInstance/axiosInstance";
import { useWebsiteApi } from "../../context/websiteContext";
import Select from "react-select";
import { useParams } from "react-router-dom";
import { decryptId } from "../../utils/cryptoUtils";

const SendOpinion = () => {
  const { userIdE, patientIdE } = useParams();
  const userId = decryptId(userIdE);
  const patientId = decryptId(patientIdE);
  const [selectedHospitals, setSelectedHospitals] = useState([]);
  const [caseHistroy, setCaseHistroy] = useState("");
  const [healthIssue, setHealthIssue] = useState("");
  const [doctorName, setDoctorName] = useState("");
  const [loading, setLoading] = useState(false);
  const { getAllHospitals, hospitals } = useWebsiteApi();

  const handleChange = (selectedOptions) => {
    setSelectedHospitals(selectedOptions);
  };

  useEffect(() => {
    getAllHospitals();
  }, []);

  const handleSendEmail = () => {
    setLoading(true);
    if (!caseHistroy) {
      alert("Please enter the case history.");
      setLoading(false);
      return;
    }

    if (!healthIssue) {
      alert("Please enter the health issue.");
      setLoading(false);
      return;
    }
    if (selectedHospitals.length > 0) {
      const hospitalDetails = selectedHospitals.map((selectedHospital) => {
        const fullDetails = hospitals.find(
          (hospital) => hospital.id === selectedHospital.value
        );
        return {
          id: fullDetails.id,
          name: fullDetails.name,
          hospital_email: fullDetails.hospital_email,
          hospital_phone: fullDetails.hospital_phone,
        };
      });
      axiosInstance
        .post("/sendMailtoHospital", {
          hospitals: hospitalDetails,
          user_id: userId,
          patient_id: patientId,
          case_history: caseHistroy,
          health_issue: healthIssue,
          doctor_name: doctorName,
        })
        .then((response) => {
          alert("Emails sent successfully.");
          setSelectedHospitals([]);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error:", error);
          alert("Failed to send emails.");
          setLoading(false);
        });
    } else {
      alert("Please select at least one hospital.");
      setLoading(false);
    }
  };

  const hospitalOptions = hospitals.map((hospital) => ({
    value: hospital.id,
    label: hospital.name,
  }));

  return (
    <>
      {loading && (
        <div className="loading-container">
          <div className="loading-spinner"></div>
        </div>
      )}
      <section id="home-mod">
        <div className="home-mod">
          <LeftSlider />
          <div className="home-mod-right">
            <div className="scrollbar-1">
              <div className="query-form-page">
                <h2 style={{ color: "" }}>Hospital Opinion Request</h2>
                <div className="visa-form">
                  <div className="visa-form-box">
                    <label>Select Hospital</label>
                    <Select
                      isMulti
                      name="hospitals"
                      options={hospitalOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      value={selectedHospitals}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="visa-form-box">
                    <label>Case History</label>
                    <input
                      type="text"
                      value={caseHistroy}
                      onChange={(e) => setCaseHistroy(e.target.value)}
                    />
                  </div>
                  <div className="visa-form-box">
                    <label>Health Issue</label>
                    <input
                      type="text"
                      name="healthIssue"
                      value={healthIssue}
                      onChange={(e) => setHealthIssue(e.target.value)}
                    />
                  </div>
                  <div className="visa-form-box">
                    <label>Doctor Name</label>
                    <input
                      type="text"
                      name="doctorName"
                      value={doctorName}
                      onChange={(e) => setDoctorName(e.target.value)}
                    />
                  </div>
                  <div className="visa-form-box" onClick={handleSendEmail}>
                    <button type="submit" name="en" className="visa-submit">
                      {" "}
                      Submit Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SendOpinion;
