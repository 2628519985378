// src/utils/notificationUtils.js

/**
 * Requests permission from the user to show notifications.
 */
export const requestNotificationPermission = () => {
    if ("Notification" in window) {
      return Notification.requestPermission();
    } else {
      console.warn("Notifications are not supported by this browser.");
    }
  };
  
  /**
   * Sends a browser notification.
   * @param {string} title - Title of the notification.
   * @param {Object} options - Options for the notification (body, icon, etc.).
   */
  export const sendNotification = (title, options = {}) => {
    if (Notification.permission === "granted") {
      new Notification(title, options);
    } else {
      console.warn("Notification permission not granted.");
    }
  };
  