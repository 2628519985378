import React, { useState } from "react";
import TreatmentList from "../../HomePage/components/TreatmentList";

const AdminFilter = ({
  setPlatformSelect,
  platformSelect,
  setSelectedTreatment,
  selectedTreatment,
  setLeadStatus,
  leadStatus,
}) => {
  const [showFilter, setShowFilter] = useState(false);

  // const { setPlatformSelect, platformSelect } = useGetLeadApi();

  const handlePlatFormSelect = (e) => {
    setLeadStatus("");
    setSelectedTreatment("");
    setPlatformSelect(e.target.value);
  };

  const handleLeadStatus = (e) => {
    setPlatformSelect("");
    setSelectedTreatment("");
    setLeadStatus(e.target.value);
  };

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };
  return (
    <>
      <div className="filters-box toggle" onClick={toggleFilter}>
        Filters <img src="images/2024/01/filters.png" />
      </div>

      <div id="target" style={{ display: showFilter ? "block" : "none" }}>
        <div className="ding">
          <select
            id="wiki"
            value={platformSelect}
            onChange={handlePlatFormSelect}
          >
            <option value="">Lead Type</option>
            <option value="Hot">Hot</option>
            <option value="Warm">Warm</option>
            <option value="Cold">Cold</option>
            <option value="Dead">Dead</option>
          </select>
        </div>

        <div className="ding">
          <select id="wiki" value={leadStatus} onChange={handleLeadStatus}>
            <option value="">Lead Status</option>
            <option value="Close Lead">Close Lead</option>
            <option value="Open Lead">Open Lead</option>
          </select>
        </div>

        <TreatmentList
          setSelectedTreatment={setSelectedTreatment}
          selectedTreatment={selectedTreatment}
        />
        {/* <div className="ding">
          <select id="wiki">
            <option value="none" selected>
              {" "}
              Select Speciality{" "}
            </option>
            <option value="All"> Speciality name</option>
          </select>
        </div>
        <div className="ding">
          <select id="wiki">
            <option value="none" selected>
              {" "}
              Select Status{" "}
            </option>
            <option value="All"> Not Uploaded</option>
          </select>
        </div> */}
      </div>
    </>
  );
};

export default AdminFilter;
