import React, { createContext, useState, useContext } from "react";
import {
    axiosWebsiteInstance,
    axiosInstance,
} from "../axiosInstance/axiosInstance";

const GetWebsiteDataContext = createContext();

export const WebsiteProvider = ({ children }) => {
    const [treatmentsList, setTreatmentsList] = useState([]);
    const [hospitals, setHospitals] = useState([]);
    const [treatmentUploadList, setTreatmentUploadList] = useState([]);
    const [doctors, setDoctors] = useState([]);
    const [cachedHospitals, setCachedHospitals] = useState(null);
    const [cachedTreatments, setCachedTreatments] = useState(null);
    const [cachedSpeciality, setCachedSpeciality] = useState(null);

    const getAllTreatment = async () => {
        try {
            if (cachedTreatments) {
                // Use cached treatments if available
                setTreatmentsList(cachedTreatments);
            } else {
                // Fetch treatments and cache them
                const response = await axiosWebsiteInstance.get("/treatments");
                const fetchedTreatments = response.data.data.treatments;
                setTreatmentsList(fetchedTreatments);
                setCachedTreatments(fetchedTreatments);
            }
        } catch (err) {
            console.error("Error fetching treatments:", err.message);
        }
    };

    const getAllUploadedTreatment = async () => {
        try {
            if (cachedSpeciality) {
                setTreatmentUploadList(cachedSpeciality);
            } else {
                const response = await axiosInstance.get("/SpecialityList");
                setTreatmentUploadList(response.data.specialityList);
                setCachedSpeciality(response.data.specialityList);
            }
        } catch (err) {
            console.log(err.message);
        }
    };

    const getAllHospitals = async () => {
        try {
            if (cachedHospitals) {
                setHospitals(cachedHospitals);
            } else {
                const response = await axiosWebsiteInstance.get("/search");
                const fetchedHospitals = response.data.searchData.hospitals;
                setHospitals(fetchedHospitals);
                setDoctors(response.data.searchData.doctors);
                setCachedHospitals(fetchedHospitals);
            }
        } catch (err) {
            console.error(err.message);
        }
    };

    const getHospitalNameById = (hospitalId, hospitals) => {
        const hospital = hospitals.find((h) => h.id === parseInt(hospitalId));
        return hospital ? hospital.name : "Hospital Not Found";
    };

    const getHospitalImageById = (hospitalId, hospitals) => {
        const hospital = hospitals.find((h) => h.id === parseInt(hospitalId));
        return hospital ? hospital.icon : "Hospital icon not Found";
    };

    return (
        <GetWebsiteDataContext.Provider
            value={{
                treatmentsList,
                getAllTreatment,
                getAllHospitals,
                hospitals,
                getAllUploadedTreatment,
                treatmentUploadList,
                getHospitalNameById,
                getHospitalImageById,
                doctors,
            }}
        >
            {children}
        </GetWebsiteDataContext.Provider>
    );
}

export const useWebsiteApi = () => {
    return useContext(GetWebsiteDataContext);
};