import React from "react";
import { FaInstagram, FaFacebookF, FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";
import { BsBrowserChrome } from "react-icons/bs";
import { encryptId } from "../../utils/cryptoUtils";
import { IoPersonAdd } from "react-icons/io5";
import LeadType from "../../common/LeadType";

const LeadList = ({
  adminLeadList,
  teamData,
  userId,
  toggleEdit,
  showEdit,
}) => {
  const AssignName = teamData.find((e) => e?.id == userId);

  const formatDate = (isoString) => {
    const date = new Date(isoString);

    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    const hours = date.getHours();
    const minutes = date.getMinutes();
    const period = hours >= 12 ? "PM" : "AM";

    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, "0");

    return `${day}${getDaySuffix(
      day
    )} ${month} ${year} ${formattedHours}:${formattedMinutes} ${period}`;
  };

  const getDaySuffix = (day) => {
    if (day > 3 && day < 21) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };
  return (
    <>
      <div className="scrollbar">
        {adminLeadList?.map((lead, index) => {
          return (
            <div
              className="leads-patient-doc-crm"
              style={{
                background: lead?.status === "Close Lead" ? "#A9A9A9" : "",
              }}
              title={lead?.status === "Close Lead" ? "This lead is closed" : ""}
              key={index}
            >
              <div className="leads-patient-name" style={{ display: "flow" }}>
                {lead?.patient_name}{" "}
                <div style={{ color: "#3e3eff" }}>
                  {formatDate(lead?.created_at)}{" "}
                </div>
              </div>
              <div className="leads-patient-country">{lead?.country}</div>
              <div className="leads-patient-source">
                {lead?.platform === "Whatsapp" ? (
                  <i>
                    <FaWhatsapp className="fa-whatsapp" fontSize={25} />
                  </i>
                ) : lead?.platform === "Website" ? (
                  <i>
                    <BsBrowserChrome className="fab fa-website" fontSize={23} />
                  </i>
                ) : lead?.platform === "Facebook" ? (
                  <i>
                    <FaFacebookF className="fa-facebook-f" />
                  </i>
                ) : lead?.platform?.includes("Bharatonecare-LP") ? (
                  <i>
                    <BsBrowserChrome className="fab fa-website" fontSize={23} />
                  </i>
                ) : (
                  <i>
                    <IoPersonAdd
                      className="fa fa-website"
                      style={{ background: "#737373" }}
                    />
                  </i>
                )}
              </div>
              <div className="leads-patient-speciality">
                <LeadType leadId={lead?.id} leadtype={lead?.lead_type} />
              </div>
              {lead?.patient_id ? (
                <div
                  className="leads-patient-report-status"
                  style={{ cursor: "pointer", color: "green" }}
                  onClick={(e) => toggleEdit(lead?.patient_id)}
                >
                  Edit Patient
                </div>
              ) : (
                <div className="leads-patient-report-status">Assign First</div>
              )}
              <div className="leads-patient-referred" style={{ color: "#000" }}>
                {AssignName?.name}
              </div>
              <div
                className="leads-patient-status"
                style={{
                  color: lead?.status === "Close Lead" ? "#c70000" : "green",
                }}
              >
                {lead?.status === "Close Lead" ? "Close Lead" : "Open Lead"}
              </div>
              {lead?.user_id && (
                <div className="leads-patient-view">
                  <Link
                    to={`/patient/${encryptId(lead?.id)}`}
                    data-popup-open="popup-7"
                    className="view"
                  >
                    View
                  </Link>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default LeadList;
