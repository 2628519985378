import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import SetReminderForm from "./SetReminderForm";

const UploadOpinion = ({
  patientId,
  userId,
  opinions,
  patientName,
  getBackgroundColor,
  leadDetail,
  getHospitalNameById,
  hospitals,
  encryptId,
  getHospitalImageById,
  reminderList,
  getReminderList
}) => {
  return (
    <>
      <div className="patient-dashboard-reports">
        <h2>
          Hospital Opinion{" "}
          <Link to={`/upload-opinion/${userId}/${patientId}`}>
            Upload Opinion
          </Link>
        </h2>
        {opinions?.length > 0 ? (
          <div className="opinion-grid">
            {opinions.map((opinion) => (
              <div key={opinion?.id} className="opinion-item">
                <Link
                  to={`/opinion-detail/${encryptId(
                    opinion?.patient_id
                  )}/${encryptId(opinion?.id)}`}
                >
                  <img
                    src={`${process.env.REACT_APP_IMAGE_URL}/hospital/${getHospitalImageById(
                      opinion?.hospital_id,
                      hospitals
                    )}`}
                    alt="Hospital"
                    className="hospital-image"
                  />
                  <span className="hospital-name">
                    {getHospitalNameById(opinion?.hospital_id, hospitals)}
                  </span>
                </Link>
              </div>
            ))}
          </div>
        ) : (
          <p>No uploaded opinions available.</p>
        )}

        <SetReminderForm
          patientId={patientId}
          agent_id={userId}
          reminderType="Hospital Opinion"
          reminderList={reminderList}
          getBackgroundColor={getBackgroundColor}
          getReminderList={getReminderList}
        />

        {/* {opinions?.length > 0 ? (
          <div className="opinion-table-container">
            <table className="opinion-table">
              <thead>
                <tr>
                  <th
                    style={{
                      background: getBackgroundColor(leadDetail?.lead_type),
                    }}
                  >
                    ID
                  </th>
                  <th
                    style={{
                      background: getBackgroundColor(leadDetail?.lead_type),
                    }}
                  >
                    Patient Name
                  </th>
                  <th
                    style={{
                      background: getBackgroundColor(leadDetail?.lead_type),
                    }}
                  >
                    Hospital Name
                  </th>
                  <th
                    style={{
                      background: getBackgroundColor(leadDetail?.lead_type),
                    }}
                  >
                    Report/Opinion
                  </th>
                  <th
                    style={{
                      background: getBackgroundColor(leadDetail?.lead_type),
                    }}
                  >
                    Created At
                  </th>
                </tr>
              </thead>
              <tbody>
                {opinions?.map((opinion) => (
                  <tr key={opinion.id}>
                    <td>{opinion.id}</td>
                    <td>{patientName}</td>
                    <td>
                      {getHospitalNameById(opinion.hospital_id, hospitals)}
                    </td>
                    <td>
                      {opinion.description ? (
                        <p>{opinion.description}</p>
                      ) : (
                        <a
                          href={opinion.reports}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View File
                        </a>
                      )}
                    </td>
                    <td>{new Date(opinion.created_at).toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p>No uploaded opinions available.</p>
        )} */}
      </div>
    </>
  );
};

export default UploadOpinion;
