import React from "react";
import { Link } from "react-router-dom";

const PostArrivalList = ({
  postArrivalList,
  encryptId,
  hospitals,
  getHospitalNameById,
  getHospitalImageById,
}) => {
  return (
    <>
      {postArrivalList?.length > 0 ? (
        <div className="opinion-grid">
          {postArrivalList.map((opinion) => (
            <div key={opinion?.id} className="opinion-item">
              <Link
                to={`/post-arrival-detail/${encryptId(
                  opinion?.patient_id
                )}/${encryptId(opinion?.user_id)}/${encryptId(opinion?.id)}`}
              >
                <img
                  src={`${process.env.REACT_APP_IMAGE_URL}/hospital/${getHospitalImageById(
                    opinion?.hospital_id,
                    hospitals
                  )}`}
                  alt="Hospital"
                  className="hospital-image"
                />
                <span className="hospital-name">
                  {getHospitalNameById(opinion?.hospital_id, hospitals)}
                </span>
              </Link>
            </div>
          ))}
        </div>
      ) : (
        <p>No data updated.</p>
      )}
    </>
  );
};

export default PostArrivalList;
