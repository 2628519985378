import React, { useState, useEffect } from "react";
import { axiosInstance } from "../../axiosInstance/axiosInstance";
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";

const WhatsAppTemplateList = ({
  userId,
  patientId,
  getWhatsappTemplateList,
  leadPhoneNumber,
  leadName,
}) => {
  const [templateList, setTemplateList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get("/get-double-tick-template");
      // const filteredTemplates = response.data?.data.filter((template) =>
      //   template.components.some(
      //     (component) =>
      //       component.type === "BODY" && component.variables.length === 0
      //   )
      // );
      setTemplateList(response.data?.data);
    } catch (error) {
      alert("An error occurred while fetching data.");
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const sendMessage = async (selectedTemplate) => {
    if (!selectedTemplate) {
      alert("Please select a template!");
      return;
    }
    // Check if the selected template has variables in the BODY component
    const bodyComponent = selectedTemplate.components.find(
      (component) => component.type === "BODY"
    );
    const hasVariables =
      bodyComponent &&
      bodyComponent.variables &&
      bodyComponent.variables.length > 0;

    setLoading(true);
    try {
      const messageData = {
        messages: [
          {
            content: {
              language: selectedTemplate.language, // Use the language of the template
              templateName: selectedTemplate.name, // Use the selected template name
            },
            from: "+919076557454",
            to: leadPhoneNumber,
            messageId: uuidv4(),
          },
        ],
      };

      // If there are variables, add placeholders
      if (hasVariables) {
        messageData.messages[0].content.templateData = {
          body: {
            placeholders: bodyComponent.variables.map((variable) => leadName), // Add placeholder values based on variables
          },
        };
      }

      const response = await axios.post(
        "https://public.doubletick.io/whatsapp/message/template",
        messageData,
        {
          headers: {
            accept: "application/json",
            "content-type": "application/json",
            Authorization: "key_QuyyKsnmcx ",
          },
        }
      );

      if (response.data?.messages[0]?.status === "SENT") {
        alert("Template Sent Successfully");
        handleSelectChange(selectedTemplate);
      } else {
        alert("Error sending template");
      }
    } catch (error) {
      console.error(error);
      alert("Failed to send the template");
    } finally {
      setLoading(false);
    }
  };

  const handleTemplateSelect = (selectedTemplate) => {
    const userConfirmed = window.confirm(
      `Do you want to send the template "${selectedTemplate.label}"?`
    );
    if (userConfirmed) {
      sendMessage(selectedTemplate);
    }
  };

  const handleSelectChange = async (selectedOption) => {
    if (!selectedOption) return;

    setLoading(true);

    const {
      id: template_id,
      name: template_name,
      language: template_language,
      category: template_category,
      components,
      status: template_status,
      wabaPhoneNumber: template_wabaPhoneNumber,
      createdBy: template_createdBy,
      rejectedReason: template_rejectedReason,
    } = selectedOption;

    const template_components_body =
      components.find((comp) => comp.type === "BODY")?.text || "";

    // Make POST request
    try {
      const response = await axiosInstance.post("/double-tick-post-data", {
        template_id,
        template_name,
        template_language,
        template_category,
        template_components_body,
        template_status,
        template_wabaPhoneNumber,
        template_createdBy,
        template_rejectedReason,
        user_id: userId,
        patient_id: patientId,
      });
      getWhatsappTemplateList(userId, patientId);
    } catch (error) {
      alert("An error occurred while sending the template.");
      console.error("Error sending template:", error);
    } finally {
      setLoading(false);
    }
  };

  const options = templateList?.map((template) => ({
    value: template.id,
    label: template.name,
    ...template,
  }));

  return (
    <>
      {loading && (
        <div className="loading-container">
          <div className="loading-spinner"></div>
        </div>
      )}
      <Select
        options={options}
        onChange={handleTemplateSelect}
        placeholder="Select a template"
        isDisabled={loading}
        value={selectedTemplate}
        className="basic-multi-select"
        classNamePrefix="select"
      />
    </>
  );
};

export default WhatsAppTemplateList;
