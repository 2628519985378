import axios from "axios";

const axiosInstance = axios.create({
  baseURL: `${ process.env.REACT_APP_CRM_URL}`,
});

const axiosWhatsappInstance = axios.create({
  baseURL: "https://public.doubletick.io",
});

const axiosWebsiteInstance = axios.create({
  baseURL: `${ process.env.REACT_APP_WEBSITE_URL}`,
});

export { axiosInstance, axiosWhatsappInstance, axiosWebsiteInstance };
