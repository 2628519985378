import React from "react";
import { axiosInstance } from "../../axiosInstance/axiosInstance";

const PassportList = ({
  patientPassport,
  formatDate,
  getPatientPassport,
  patinetId,
}) => {
  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this Passport?"
    );
    if (confirmDelete) {
      try {
        const response = await axiosInstance.post(`/passport-delete`, {
          id: id,
          patient_id: patinetId,
        });

        alert("Report deleted successfully");
        // getLeadbyId(leadId);
        getPatientPassport(patinetId);
      } catch (error) {
        console.error("There was an error deleting the user!", error);
        alert("An error occurred while deleting the user");
      }
    }
  };
  return (
    <>
      <div className="dashboard-upload-reportsbox">
        {patientPassport && patientPassport.length > 0 ? (
          patientPassport?.map((e) => {
            // Array of passport URLs to iterate over
            const passports = [
              {
                label: "Patient Passport",
                url: e?.upload_passport,
              },
              {
                label: "Attendant Passport 1",
                url: e?.upload_attandant_passport1,
              },
              {
                label: "Attendant Passport 2",
                url: e?.upload_attandant_passport2,
              },
              {
                label: "Attendant Passport 3",
                url: e?.upload_attandant_passport3,
              },
            ];

            return passports.map(
              (passport, index) =>
                passport?.url &&
                passport?.url.length >
                  "${process.env.REACT_APP_IMAGE_URL}/crm/passport/".length && (
                  <div
                    className="dashboard-upload-reports-file"
                    key={`${e.id}-${index}`}
                  >
                    <a
                      className="deleted-icon"
                      onClick={() => handleDelete(e.id)}
                      style={{ cursor: "pointer" }}
                    >
                      <img src="/images/del.png" alt="Delete Icon" />
                    </a>
                    <div className="report-preview">
                      {passport?.url.endsWith(".pdf") ? (
                        <embed
                          src={passport?.url}
                          type="application/pdf"
                          alt="Report Preview"
                        />
                      ) : (
                        <img src={passport?.url} alt="Report Preview" />
                      )}
                    </div>
                    <a
                      href={passport.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="upload-reports-text">
                        <h4>
                          {passport.label}{" "}
                          <a
                            href={passport.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src="/images/2024/01/arrow-icon.png"
                              alt="Arrow Icon"
                            />
                          </a>
                        </h4>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: formatDate(e?.created_at),
                          }}
                        />
                      </div>
                    </a>
                  </div>
                )
            );
          })
        ) : (
          <p>No data found</p>
        )}

        {/* <div className="dashboard-upload-file-box">
                      <div className="file-input">
                        <img src="/images/2024/01/icon-pass.png" alt="" />
                        <input type="file" />
                        <span className="label" data-js-label="">
                          Complete opinion before applying for passport
                        </span>
                      </div>
                    </div> */}
      </div>
    </>
  );
};

export default PassportList;
