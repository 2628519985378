import React, { useEffect } from "react";

const ShowTemplateSend = ({
  userId,
  patientId,
  formatDate,
  getWhatsappTemplateList,
  templateSend,
}) => {
  useEffect(() => {
    getWhatsappTemplateList(userId, patientId);
  }, [userId, patientId]);

  return (
    <>
      {" "}
      <div className="patient-dashboard-case-left">
        <h2>Template Send History </h2>

        <ul>
          {templateSend?.map((e) => (
            <li className="active" key={e?.id}>
              <div className="updates-img "></div>
              <p>{e?.template_name}</p>
              <p
                dangerouslySetInnerHTML={{
                  __html: formatDate(e?.created_at),
                }}
                style={{ color: "#ef5da2" }}
              />
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default ShowTemplateSend;
