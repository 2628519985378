import React, { useEffect } from "react";
import LeftSlider from "../../Inc/LeftSlider";
import { decryptId, encryptId } from "../../utils/cryptoUtils";
import { useParams } from "react-router-dom";
import { useGetLeadApi } from "../../context/getAllLeadContext";
import { useWebsiteApi } from "../../context/websiteContext";
import "./opinion.css";
import { Link } from "react-router-dom";

const OpinionDetail = () => {
  const { did, idd } = useParams();
  const { getUploadOpinion, getOpinionUpload } = useGetLeadApi();
  const { getHospitalNameById, getAllHospitals, hospitals ,getHospitalImageById } = useWebsiteApi();
  const patientId = decryptId(did);
  const id = decryptId(idd);

  useEffect(() => {
    getUploadOpinion(patientId);
    getAllHospitals();
  }, [patientId]);

  const opinion = getOpinionUpload?.find((item) => item.id === parseInt(id));


  return (
    <>
      <section id="home-mod">
        <div className="home-mod">
          <LeftSlider />
          <div className="home-mod-right">
            {/* <h1>Manish</h1> */}

            <div className="opinion-table-container">
              <h1 style={{ fontSize: "31px" }}>
                Opinion Of{" "}
                <span style={{ color: "#ef5da2" }}>
                  {getHospitalNameById(opinion?.hospital_id, hospitals)}
                </span>{" "}
              </h1>
              <table className="opinion-table">
                <thead>
                  <tr>
                    <th>ID</th>
                    {/* <th>Patient Name</th> */}
                    <th>Hospital Name</th>
                    <th>Report/Opinion</th>
                    <th>Cost</th>
                    <th>Created At</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{opinion.id}</td>
                    {/* <td>{patientName}</td> */}
                    <td>
                      {getHospitalNameById(opinion?.hospital_id, hospitals)}
                    </td>
                    <td>
                      {opinion.description ? (
                        <p>{opinion.description}</p>
                      ) : (
                        <div className="report-preview">
                          {opinion.reports?.endsWith(".pdf") ? (
                            <embed
                              src={opinion.reports}
                              type="application/pdf"
                              className="pdf-preview"
                              title="PDF Preview"
                            />
                          ) : (
                            <img
                              src={opinion.reports}
                              alt="Image Preview"
                              className="image-preview"
                            />
                          )}
                        </div>
                      )}
                      {!opinion.description && (
                        <a
                          href={opinion.reports}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="view-file-link"
                        >
                          View Opinion
                        </a>
                      )}
                    </td>
                    <td>{opinion?.cost}</td>
                    <td>{new Date(opinion.created_at).toLocaleString()}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h2
              style={{
                fontSize: "28px",
                marginTop: "20px",
                textAlign: "start",
              }}
            >
              List of all opinion{" "}
            </h2>
            {getOpinionUpload?.length > 0 ? (
              <div className="opinion-grid">
                {getOpinionUpload.map((opinion) => (
                  <div key={opinion?.id} className="opinion-item">
                    <Link
                      to={`/opinion-detail/${encryptId(
                        opinion?.patient_id
                      )}/${encryptId(opinion?.id)}`}
                    >
                      <img
                       src={`${process.env.REACT_APP_IMAGE_URL}/hospital/${getHospitalImageById(
                        opinion?.hospital_id,
                        hospitals
                      )}`}
                        alt="Hospital"
                        className="hospital-image"
                      />
                      <span className="hospital-name">
                        {getHospitalNameById(opinion?.hospital_id, hospitals)}
                      </span>
                    </Link>
                  </div>
                ))}
              </div>
            ) : (
              <p>No uploaded opinions available.</p>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default OpinionDetail;
