import React, { useState, useEffect } from "react";
import LeftSlider from "../../Inc/LeftSlider";
import { useWebsiteApi } from "../../context/websiteContext";
import Select from "react-select";
import { useParams } from "react-router-dom";
import "../upload-passport/uploadPassport.css";
import { axiosInstance } from "../../axiosInstance/axiosInstance";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./sendVisa.css";
import { format } from "date-fns";
import { decryptId } from "../../utils/cryptoUtils";
const SendVisa = () => {
  const { userIdE, patientIdE, specE } = useParams();
  const [selectedHospitals, setSelectedHospitals] = useState([]);
  const { getAllHospitals, hospitals } = useWebsiteApi();
  const [loading, setLoading] = useState(false);

  const userId = decryptId(userIdE);
  const patientId = decryptId(patientIdE);
  const spec = decryptId(specE);

  const [formData, setFormData] = useState({
    passport_number: "",
    attendant_name1: "",
    att_passport_number1: "",
    attendant_name2: "",
    att_passport_number2: "",
    attendant_name3: "",
    att_passport_number3: "",
    attendant_name4: "",
    att_passport_number4: "",
    embassy: "",
    patient_passport: "",
    attendant_passport1: "",
    attendant_passport2: "",
    attendant_passport3: "",
    attendant_passport4: "",
    country: "",
    city: "",
    appointment_date: null,
    patient_id: "",
    user_id: "",
    review_reported_by: "",
    hospitals: [],
    speciality_id: "",
  });

 

  useEffect(() => {
    getAllHospitals();
  }, []);

  const handleDateChange = (date) => {
    setFormData((prevState) => ({
      ...prevState,
      appointment_date: date,
    }));
  };

  const handleChange = (selectedOptions) => {
    setSelectedHospitals(selectedOptions);
  };

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value, files } = e.target;

    if (files && files[0]) {
      const file = files[0];
      const fileType = file.type;

      if (fileType !== "application/pdf") {
        alert("Only PDF files are allowed.");
        return; // Stop processing if the file is not a PDF
      }

      setFormData((prevData) => ({
        ...prevData,
        [name]: file,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const hospitalOptions = hospitals.map((hospital) => ({
    value: hospital.id,
    label: hospital.name,
  }));

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!formData.passport_number || formData.passport_number.trim() === "") {
      alert("Please enter the passport number.");
      setLoading(false);
      return;
    }
    if (!formData.patient_passport) {
      alert("Please upload the patient passport.");
      setLoading(false);
      return;
    }
    if (!formData.country || formData.country.trim() === "") {
      alert("Please select a country.");
      setLoading(false);
      return;
    }
    if (!formData.appointment_date) {
      alert("Please select an appointment date.");
      setLoading(false);
      return;
    }

    if (selectedHospitals.length > 0) {
      const hospitalDetails = selectedHospitals.map((selectedHospital) => {
        const fullDetails = hospitals.find(
          (hospital) => hospital.id === selectedHospital.value
        );
        return {
          id: fullDetails.id,
          name: fullDetails.name,
          hospital_email: fullDetails.hospital_email,
          hospital_phone: fullDetails.hospital_phone,
        };
      });

      const formatDate = (date) => {
        const day = format(date, "do");
        const month = format(date, "MMMM");
        const year = format(date, "yyyy");
        const time = format(date, "h:mm a");
        return `${day} ${month} ${year} ${time}`;
      };

      const currentDateTime = formatDate(new Date());

      const formDataToSend = new FormData();

      // Append form fields to FormData
      for (const key in formData) {
        if (formData[key]) {
          formDataToSend.append(key, formData[key]);
        } else {
          formDataToSend.append(key, null);
        }
      }

      // Convert hospitalDetails to JSON and append
      formDataToSend.append("hospitals", JSON.stringify(hospitalDetails));
      formDataToSend.append("patient_id", patientId);
      formDataToSend.append("user_id", userId);
      formDataToSend.append("speciality_id", spec);
      formDataToSend.append("created_date", currentDateTime);

      try {
        const response = await axiosInstance.post(
          "/postVisatoHospital",
          formDataToSend,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        alert("Visa Send to Hospital Successfully");
      } catch (error) {
        console.error("Error submitting form:", error);
      } finally {
        setLoading(false);
      }
    } else {
      alert("No hospitals selected.");
      setLoading(false);
    }
  };

  return (
    <>
      {loading && (
        <div className="loading-container">
          <div className="loading-spinner"></div>
        </div>
      )}
      <section id="home-mod">
        <div className="home-mod">
          <LeftSlider />
          <div className="home-mod-right">
            <div className="scrollbar-1">
              <div className="query-form-page">
                <div className="patient-visa-right">
                  <h2>Hospital Visa Letter</h2>
                  <div className="visa-form">
                    <div className="visa-form-box">
                      <label>Select Hospital</label>
                      <Select
                        isMulti
                        name="hospitals"
                        options={hospitalOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        value={selectedHospitals}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="visa-form-box">
                      <label>Passport Number</label>
                      <input
                        type="text"
                        name="passport_number"
                        value={formData.passport_number}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="visa-form-box">
                      <label>Patient Passport</label>
                      <input
                        type="file"
                        name="patient_passport"
                        onChange={handleInputChange}
                      />
                      {formData.patient_passport &&
                      formData.patient_passport.type === "application/pdf" ? (
                        <iframe
                          src={URL.createObjectURL(formData.patient_passport)}
                          style={{
                            width: "100%",
                            height: "500px", // Adjust height as needed
                            border: "1px solid #ccc",
                          }}
                          title="Patient Passport PDF"
                        />
                      ) : (
                        <p>
                          No PDF file selected or selected file is not a PDF.
                        </p>
                      )}
                    </div>
                    <div className="visa-form-box">
                      <label>Attendant Name 1</label>
                      <input
                        type="text"
                        name="attendant_name1"
                        value={formData.attendant_name1}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="visa-form-box">
                      <label>Attendant Passport Number 1</label>
                      <input
                        type="text"
                        name="att_passport_number1"
                        value={formData.att_passport_number1}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="visa-form-box">
                      <label>Attendant Passport 1</label>
                      <input
                        type="file"
                        name="attendant_passport1"
                        onChange={handleInputChange}
                      />
                      {formData.attendant_passport1 &&
                      formData.attendant_passport1.type ===
                        "application/pdf" ? (
                        <iframe
                          src={URL.createObjectURL(
                            formData.attendant_passport1
                          )}
                          style={{
                            width: "100%",
                            height: "500px", // Adjust height as needed
                            border: "1px solid #ccc",
                          }}
                          title="Patient Passport PDF"
                        />
                      ) : (
                        <p>
                          No PDF file selected or selected file is not a PDF.
                        </p>
                      )}
                    </div>
                    <div className="visa-form-box">
                      <label>Attendant Name 2</label>
                      <input
                        type="text"
                        name="attendant_name2"
                        value={formData.attendant_name2}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="visa-form-box">
                      <label>Attendant Passport Number 2</label>
                      <input
                        type="text"
                        name="att_passport_number2"
                        value={formData.att_passport_number2}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="visa-form-box">
                      <label>Attendant Passport 2</label>
                      <input
                        type="file"
                        name="attendant_passport2"
                        onChange={handleInputChange}
                      />
                      {formData.attendant_passport2 &&
                      formData.attendant_passport2.type ===
                        "application/pdf" ? (
                        <iframe
                          src={URL.createObjectURL(
                            formData.attendant_passport2
                          )}
                          style={{
                            width: "100%",
                            height: "500px", // Adjust height as needed
                            border: "1px solid #ccc",
                          }}
                          title="Patient Passport PDF"
                        />
                      ) : (
                        <p>
                          No PDF file selected or selected file is not a PDF.
                        </p>
                      )}
                    </div>
                    <div className="visa-form-box">
                      <label>Attendant Name 3</label>
                      <input
                        type="text"
                        name="attendant_name3"
                        value={formData.attendant_name3}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="visa-form-box">
                      <label>Attendant Passport Number 3</label>
                      <input
                        type="text"
                        name="att_passport_number3"
                        value={formData.att_passport_number3}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="visa-form-box">
                      <label>Attendant Passport 3</label>
                      <input
                        type="file"
                        name="attendant_passport3"
                        onChange={handleInputChange}
                      />
                      {formData.attendant_passport3 &&
                      formData.attendant_passport3.type ===
                        "application/pdf" ? (
                        <iframe
                          src={URL.createObjectURL(
                            formData.attendant_passport3
                          )}
                          style={{
                            width: "100%",
                            height: "500px", // Adjust height as needed
                            border: "1px solid #ccc",
                          }}
                          title="Patient Passport PDF"
                        />
                      ) : (
                        <p>
                          No PDF file selected or selected file is not a PDF.
                        </p>
                      )}
                    </div>
                    <div className="visa-form-box">
                      <label>Attendant Name 4</label>
                      <input
                        type="text"
                        name="attendant_name4"
                        value={formData.attendant_name4}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="visa-form-box">
                      <label>Attendant Passport Number 4</label>
                      <input
                        type="text"
                        name="att_passport_number4"
                        value={formData.att_passport_number4}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="visa-form-box">
                      <label>Attendant Passport 4</label>
                      <input
                        type="file"
                        name="attendant_passport4"
                        onChange={handleInputChange}
                      />
                      {formData.attendant_passport4 &&
                      formData.attendant_passport4.type ===
                        "application/pdf" ? (
                        <iframe
                          src={URL.createObjectURL(
                            formData.attendant_passport4
                          )}
                          style={{
                            width: "100%",
                            height: "500px",
                            border: "1px solid #ccc",
                          }}
                          title="Patient Passport PDF"
                        />
                      ) : (
                        <p>
                          No PDF file selected or selected file is not a PDF.
                        </p>
                      )}
                    </div>
                    <div className="visa-form-box">
                      <label>Embassy</label>
                      <input
                        type="text"
                        name="embassy"
                        value={formData.embassy}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="visa-form-box">
                      <label>Country</label>
                      <input
                        type="text"
                        name="country"
                        value={formData.country}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="visa-form-box">
                      <label>City</label>
                      <input
                        type="text"
                        name="city"
                        value={formData.city}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="visa-form-box">
                      <label>Appointment Date</label>
                      <DatePicker
                        selected={formData.appointment_date}
                        onChange={handleDateChange}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Select appointment date"
                        minDate={new Date()}
                        className="date-picker-input"
                      />
                    </div>
                    <div className="visa-form-box" onClick={handleSubmit}>
                      <button type="submit" name="en" className="visa-submit">
                        {" "}
                        Submit Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SendVisa;
