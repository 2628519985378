import React from "react";

const TicketManualReportList = ({ ticketManualList, formatDate }) => {
  return (
    <>
      <div className="dashboard-upload-reportsbox">
        {ticketManualList && ticketManualList.length > 0 ? (
          ticketManualList.map((e, index) => (
            <div className="dashboard-upload-reports-file" key={e?.id}>
              <a
                className="deleted-icon"
                href="#"
                // onClick={() => handleDelete(e.id)}
              >
                <img src="/images/del.png" />
              </a>
              <div className="report-preview">
                {e?.ticket.endsWith(".pdf") ? (
                  <embed
                    src={e?.ticket}
                    type="application/pdf"
                    alt="Report Preview"
                  />
                ) : (
                  <img src={e?.ticket} alt="Report Preview" />
                )}
              </div>
              <a href={e?.ticket} target="_blank" rel="noopener noreferrer">
                <div className="upload-reports-text">
                  <h4>
                    Report {index + 1}
                    <a
                      href={e?.ticket}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="/images/2024/01/arrow-icon.png"
                        alt="Arrow Icon"
                      />
                    </a>
                  </h4>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: formatDate(e?.created_at),
                    }}
                  />
                </div>
              </a>
            </div>
          ))
        ) : (
          <p>No Visa found</p>
        )}
      </div>
    </>
  );
};

export default TicketManualReportList;
