import React, { useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { decryptId, encryptId } from "../../utils/cryptoUtils";
import LeftSlider from "../../Inc/LeftSlider";
import { useGetLeadApi } from "../../context/getAllLeadContext";
import { useWebsiteApi } from "../../context/websiteContext";

const HospitalVisaLetter = () => {
  const { pid, uid, idd } = useParams();
  const { getVisaInvitationEmail, visaInvitationEmail } = useGetLeadApi();
  const { getHospitalNameById, getAllHospitals, hospitals ,getHospitalImageById } = useWebsiteApi();
  const patinetId = decryptId(pid);
  const userId = decryptId(uid);
  const id = decryptId(idd);

  useEffect(() => {
    getVisaInvitationEmail(patinetId, userId);
    getAllHospitals();
  }, [patinetId, userId]);

  const data = visaInvitationEmail?.find((item) => item.id === parseInt(id));

  return (
    <>
      <section id="home-mod">
        {/* <div className="head-top">
          <div className="head-top-left">
            <img src="https://bharatonecare.com/images/2024/01/logo-footer.png" />{" "}
          </div>
          <div className="head-top-right">
            <Link to={`/patient`}>
              {" "}
              <i className="fa  fa-angle-left"></i> Go back{" "}
            </Link>
          </div>
        </div> */}
        <div className="home-mod">
          <LeftSlider />
          <div className="home-mod-right">
            <div className="table-container">
              <h1 style={{ fontSize: "31px" }}>
                Hospital Visa to{" "}
                <span style={{ color: "#ef5da2" }}>
                  {getHospitalNameById(data?.hospital_id, hospitals)}
                </span>{" "}
              </h1>
              <table className="visa-invitation-table">
                <thead>
                  <tr>
                    <th>Patient Passport</th>
                    {data.attendant_name1 !== "null" && (
                      <>
                        <th>Attendant Name 1</th>
                        <th>Attendant Passport 1</th>
                      </>
                    )}
                    {data.attendant_name2 !== "null" &&
                      data.attendant_passport2?.includes("null") === false && (
                        <>
                          <th>Attendant Name 2</th>
                          <th>Attendant Passport 2</th>
                        </>
                      )}
                    {data.attendant_name3 !== "null" &&
                      data.attendant_passport3?.includes("null") === false && (
                        <>
                          <th>Attendant Name 3</th>
                          <th>Attendant Passport 3</th>
                        </>
                      )}
                    {data.attendant_name4 !== "null" &&
                      data.attendant_passport4?.includes("null") === false && (
                        <>
                          <th>Attendant Name 4</th>
                          <th>Attendant Passport 4</th>
                        </>
                      )}
                    {data.embassy && <th>Embassy</th>}
                    <th>Hospital</th>
                    <th>Appointment Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="report-preview">
                        {data.patient_passport?.endsWith(".pdf") ? (
                          <embed
                            src={data.patient_passport}
                            type="application/pdf"
                            className="pdf-preview"
                            title="PDF Preview"
                          />
                        ) : (
                          <img
                            src={data.patient_passport}
                            alt="Image Preview"
                            className="image-preview"
                          />
                        )}
                        <a
                          href={data.patient_passport}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="view-file-link"
                        >
                          {data.patient_passport !== "null"
                            ? "View Passport"
                            : "No Passport"}
                        </a>
                      </div>
                    </td>
                    {data.attendant_name1 !== "null" && (
                      <>
                        <td>{data.attendant_name1}</td>
                        <td>
                          <div className="report-preview">
                            {data.attendant_passport1?.endsWith(".pdf") ? (
                              <embed
                                src={data.attendant_passport1}
                                type="application/pdf"
                                className="pdf-preview"
                                title="PDF Preview"
                              />
                            ) : (
                              <img
                                src={data.attendant_passport1}
                                alt="Image Preview"
                                className="image-preview"
                              />
                            )}
                            <a
                              href={data.attendant_passport1}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="view-file-link"
                            >
                              {data.attendant_passport1 !== "null"
                                ? "View Passport"
                                : "No Passport"}
                            </a>
                          </div>
                        </td>
                      </>
                    )}
                    {data.attendant_name2 !== "null" &&
                      data.attendant_passport2?.includes("null") === false && (
                        <>
                          <td>{data.attendant_name2}</td>
                          <td>
                            <div className="report-preview">
                              {data.attendant_passport2?.endsWith(".pdf") ? (
                                <embed
                                  src={data.attendant_passport2}
                                  type="application/pdf"
                                  className="pdf-preview"
                                  title="PDF Preview"
                                />
                              ) : (
                                <img
                                  src={data.attendant_passport2}
                                  alt="Image Preview"
                                  className="image-preview"
                                />
                              )}
                              <a
                                href={data.attendant_passport2}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="view-file-link"
                              >
                                {data.attendant_passport2 !== "null"
                                  ? "View Passport"
                                  : "No Passport"}
                              </a>
                            </div>
                            {/* <a
                              href={data.attendant_passport2}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {data.attendant_passport2 !== "null"
                                ? "View Passport"
                                : "No Passport"}
                            </a> */}
                          </td>
                        </>
                      )}
                    {data.attendant_name3 !== "null" &&
                      data.attendant_passport3?.includes("null") === false && (
                        <>
                          <td>{data.attendant_name3}</td>
                          <td>
                            <div className="report-preview">
                              {data.attendant_passport3?.endsWith(".pdf") ? (
                                <embed
                                  src={data.attendant_passport3}
                                  type="application/pdf"
                                  className="pdf-preview"
                                  title="PDF Preview"
                                />
                              ) : (
                                <img
                                  src={data.attendant_passport3}
                                  alt="Image Preview"
                                  className="image-preview"
                                />
                              )}
                              <a
                                href={data.attendant_passport3}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="view-file-link"
                              >
                                {data.attendant_passport3 !== "null"
                                  ? "View Passport"
                                  : "No Passport"}
                              </a>
                            </div>
                            {/* <a
                              href={data.attendant_passport3}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {data.attendant_passport3 !== "null"
                                ? "View Passport"
                                : "No Passport"}
                            </a> */}
                          </td>
                        </>
                      )}
                    {data.attendant_name4 !== "null" &&
                      data.attendant_passport4?.includes("null") === false && (
                        <>
                          <td>{data.attendant_name4}</td>
                          <td>
                            <div className="report-preview">
                              {data.attendant_passport4?.endsWith(".pdf") ? (
                                <embed
                                  src={data.attendant_passport4}
                                  type="application/pdf"
                                  className="pdf-preview"
                                  title="PDF Preview"
                                />
                              ) : (
                                <img
                                  src={data.attendant_passport4}
                                  alt="Image Preview"
                                  className="image-preview"
                                />
                              )}
                              <a
                                href={data.attendant_passport4}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="view-file-link"
                              >
                                {data.attendant_passport4 !== "null"
                                  ? "View Passport"
                                  : "No Passport"}
                              </a>
                            </div>
                            {/* <a
                              href={data.attendant_passport4}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {data.attendant_passport4 !== "null"
                                ? "View Passport"
                                : "No Passport"}
                            </a> */}
                          </td>
                        </>
                      )}
                    <td>{data.embassy}</td>
                    <td>{getHospitalNameById(data.hospital_id, hospitals)}</td>
                    <td>{new Date(data.appointment_date).toLocaleString()}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h2
              style={{
                fontSize: "28px",
                marginTop: "20px",
                textAlign: "start",
              }}
            >
              List of Hospital Visa Request
            </h2>
            {visaInvitationEmail?.length > 0 ? (
              <div className="opinion-grid">
                {visaInvitationEmail.map((opinion) => (
                  <div key={opinion?.id} className="opinion-item">
                    <Link
                      to={`/hospital-visa-letter/${encryptId(
                        opinion?.patient_id
                      )}/${encryptId(opinion?.user_id)}/${encryptId(
                        opinion?.id
                      )}`}
                    >
                      <img
                         src={`${process.env.REACT_APP_IMAGE_URL}/hospital/${getHospitalImageById(
                          opinion?.hospital_id,
                          hospitals
                        )}`}
                        alt="Hospital"
                        className="hospital-image"
                      />
                      <span className="hospital-name">
                        {getHospitalNameById(opinion?.hospital_id, hospitals)}
                      </span>
                    </Link>
                  </div>
                ))}
              </div>
            ) : (
              <p>No Hospital Visa available.</p>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default HospitalVisaLetter;
