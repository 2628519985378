import React, { useState } from "react";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../axiosInstance/axiosInstance";
import { useUser } from "../../context/UserContext";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";

const Login = () => {
  const { setUser } = useUser();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError("Invalid email format");
      return false;
    }

    if (!password) {
      setError("Enter Your Password");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    if (!validateForm()) {
      return;
    }

    setLoading(true);

    try {
      const response = await axiosInstance.post("/login", {
        email: email,
        password: password,
      });
      setLoading(false);
      if (response.data.data.id) {
        const userId = response.data.data?.id;
        const userRole = response.data.data?.role;
        setUser({ id: userId, role: userRole });
        localStorage.setItem("userId", response.data.data?.id);
        localStorage.setItem("userRole", response.data.data?.role);
        localStorage.setItem("userPhone", response.data.data?.phone);
        localStorage.setItem("userEmail", response.data.data?.email);
        localStorage.setItem("userName", response.data.data?.name);
        if (response.data.data?.role === "2") {
          navigate("/admin-lead-list");
        } else {
          navigate("/");
        }
      } else if (!response.data.data.id) {
        alert("Invaild email Id & Password");
      }
    } catch (error) {
      console.error("Error logging in:", error);
      setError("Invaild email Id & Password");
      setLoading(false);
    }
  };
  return (
    <>
      <section id="login-section">
        <div className="login-mid login-mk" style={{ paddingTop: "30px" }}>
          <div className="login-box">
            <div className="login-box-left login-img-d">
              <img
                src="/images/bharatoneCare-login.webp"
                alt="login-img"
              />
            </div>
            <div className="login-box-right" style={{ marginTop: "5rem" }}>
              <h1>
                Welcome to <span style={{ color: "#ef5da2" }}>BharatOne Care</span>
              </h1>
              <form onSubmit={handleSubmit}>
                <div className="inputbox">
                  <label>Email Address</label>
                  <input
                    autoComplete="off"
                    type="email"
                    name="name"
                    value={email}
                    onChange={handleEmailChange}
                  />
                  {error && (
                    <div className="error" style={{ color: "red" }}>
                      {error}
                    </div>
                  )}
                </div>
                <div className="inputbox">
                  <label>Password</label>
                  <div className="password-input-container">
                    <input
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={handlePasswordChange}
                      name="password"
                    />
                    {showPassword ? (
                      <i className="password-toggle-icon" onClick={togglePasswordVisibility}><FaRegEyeSlash /></i>
                    ) : (
                      <i className="password-toggle-icon" onClick={togglePasswordVisibility}><FaRegEye /></i>
                    )}

                  </div>
                </div>

                <button className="login-button">
                  {loading ? "Loading..." : "Login"}
                </button>
              </form>

            </div>

          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
