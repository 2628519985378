import { axiosInstance } from "../axiosInstance/axiosInstance";
import React, { createContext, useState, useContext, useEffect } from "react";

const GetPostArrivalContext = createContext();

export const GetPostArrivalProvider = ({ children }) => {
  const [allLoading, setAllLoading] = useState(false);
  const [loadingCount, setLoadingCount] = useState(0);
  const [doctorConsultationList, setDoctorConsultationList] = useState([]);
  const [postDischargeList, setPostDischargeList] = useState([]);
  const [postArrivalList, setPostArrivalList] = useState([]);
  const [patientAdmisList, setPatientAdmisList] = useState([]);

  const handleLoading = (isLoading) => {
    if (isLoading) {
      setLoadingCount((prevCount) => prevCount + 1);
      setAllLoading(true);
    } else {
      setLoadingCount((prevCount) => {
        const newCount = prevCount - 1;
        if (newCount === 0) {
          setAllLoading(false);
        }
        return newCount;
      });
    }
  };
  const getDoctorConsultation = async (patientId, userId) => {
    if (patientId && userId) {
      handleLoading(true);
      try {
        const response = await axiosInstance.get(
          `/getdoctor_consultation/${patientId}/${userId}`
        );
        setDoctorConsultationList(response.data?.data);
      } catch (err) {
        console.log("Error", err);
      } finally {
        handleLoading(false);
      }
    }
  };

  const getPostDischargeList = async (patientId, userId) => {
    if (patientId && userId) {
      handleLoading(true);
      try {
        const response = await axiosInstance.get(
          `/post_discharge_list/${patientId}/${userId}`
        );
        setPostDischargeList(response.data?.data);
      } catch (err) {
        console.log("Error", err);
      } finally {
        handleLoading(false);
      }
    }
  };

  const getpostarrivalsList = async (patientId, userId) => {
    if (patientId && userId) {
      handleLoading(true);
      try {
        const response = await axiosInstance.get(
          `/getpostarrivals/${patientId}/${userId}`
        );
        setPostArrivalList(response.data?.data);
      } catch (err) {
        console.log("Error", err);
      } finally {
        handleLoading(false);
      }
    }
  };

  const getpatientAdmisiionList = async (patientId, userId) => {
    if (patientId && userId) {
      handleLoading(true);
      try {
        const response = await axiosInstance.get(
          `/getpatient_admission/${patientId}/${userId}`
        );
        setPatientAdmisList(response.data?.data);
      } catch (err) {
        console.log("Error", err);
      } finally {
        handleLoading(false);
      }
    }
  };
  return (
    <GetPostArrivalContext.Provider
      value={{
        doctorConsultationList,
        getDoctorConsultation,
        allLoading,
        postDischargeList,
        getPostDischargeList,
        getpostarrivalsList,
        postArrivalList,
        getpatientAdmisiionList,
        patientAdmisList,
      }}
    >
      {children}
    </GetPostArrivalContext.Provider>
  );
};

export const usePostArrivalApi = () => {
  return useContext(GetPostArrivalContext);
};
