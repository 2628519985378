import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useGetLeadApi } from "../../context/getAllLeadContext";
import { decryptId } from "../../utils/cryptoUtils";
import LeftSlider from "../../Inc/LeftSlider";
import { useWebsiteApi } from "../../context/websiteContext";

const VisaSubmisiionHospital = () => {
  const { pid, uid, idd } = useParams();
  const { getVisaUploadFile, visaUploadFile } = useGetLeadApi();
  const { getHospitalNameById, getAllHospitals, hospitals } = useWebsiteApi();
  const patinetId = decryptId(pid);
  const userId = decryptId(uid);
  const id = decryptId(idd);

  useEffect(() => {
    getVisaUploadFile(patinetId, userId);
    getAllHospitals();
  }, [patinetId, userId]);
  const visa = visaUploadFile?.find((item) => item.id === parseInt(id));
  return (
    <>
      <section id="home-mod">
        {/* <div className="head-top">
          <div className="head-top-left">
            <img src="https://bharatonecare.com/images/2024/01/logo-footer.png" />{" "}
          </div>
          <div className="head-top-right">
            <Link to={`/patient`}>
              {" "}
              <i className="fa  fa-angle-left"></i> Go back{" "}
            </Link>
          </div>
        </div> */}
        <div className="home-mod">
          <LeftSlider />
          <div className="home-mod-right">
            <h1 style={{ fontSize: "31px" }}>
              Hospital Visa Submission to{" "}
              <span style={{ color: "#ef5da2" }}>
                {getHospitalNameById(visa?.hospital_id, hospitals)}
              </span>{" "}
            </h1>
            <div className="table-container">
              <table className="visa-table">
                <thead>
                  <tr>
                    <th>ID</th>
                    {/* <th>Patient Name</th> */}
                    <th>Patient Passport Number</th>
                    <th>Patient Passport</th>
                    <th>Patient Visa</th>
                    {visa.attendant_name1 !== "null" && (
                      <>
                        <th>Attendant Name 1</th>
                        <th>Attendant Passport Number (1)</th>
                        <th>Attendant Passport 1</th>
                        <th>Attendant Visa (1)</th>
                      </>
                    )}
                    {/* <th>Attendant Name (1)</th>
                    <th>Attendant Passport Number (1)</th>
                    <th>Attendant Passport (1)</th>
                    <th>Attendant Visa (1)</th> */}
                    <th>Hospital</th>
                    <th>Country</th>
                    <th>Subject</th>
                    <th>Appointment Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr key={visa.id}>
                    <td>{visa.id}</td>
                    {/* <td>{patinetName}</td> */}
                    <td>{visa?.passport_number}</td>
                    <td>
                      <div className="report-preview">
                        {visa.patient_passport?.endsWith(".pdf") ? (
                          <embed
                            src={visa.patient_passport}
                            type="application/pdf"
                            className="pdf-preview"
                            title="PDF Preview"
                          />
                        ) : (
                          <img
                            src={visa.patient_passport}
                            alt="Image Preview"
                            className="image-preview"
                          />
                        )}
                        <a
                          href={visa.patient_passport}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="view-file-link"
                        >
                          {visa.patient_passport !== "null"
                            ? "View Passport"
                            : "No Passport"}
                        </a>
                      </div>
                      {/* <a
                        href={visa.patient_passport}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View File
                      </a> */}
                    </td>
                    <td>
                      <div className="report-preview">
                        {visa.pvisa?.endsWith(".pdf") ? (
                          <embed
                            src={visa.pvisa}
                            type="application/pdf"
                            className="pdf-preview"
                            title="PDF Preview"
                          />
                        ) : (
                          <img
                            src={visa.pvisa}
                            alt="Image Preview"
                            className="image-preview"
                          />
                        )}
                        <a
                          href={visa.pvisa}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="view-file-link"
                        >
                          {visa.patient_passport !== "null"
                            ? "View Visa"
                            : "No Visa"}
                        </a>
                      </div>
                    </td>
                    {visa.attendant_name1 !== "null" && (
                      <>
                        <td>{visa.attendant_name1}</td>
                        <td>{visa.att_passport_number1}</td>
                        <td>
                          <div className="report-preview">
                            {visa.attendant_passport1?.endsWith(".pdf") ? (
                              <embed
                                src={visa.attendant_passport1}
                                type="application/pdf"
                                className="pdf-preview"
                                title="PDF Preview"
                              />
                            ) : (
                              <img
                                src={visa.attendant_passport1}
                                alt="Image Preview"
                                className="image-preview"
                              />
                            )}
                            <a
                              href={visa.attendant_passport1}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="view-file-link"
                            >
                              {visa.patient_passport !== "null"
                                ? "View Passport"
                                : "No Passport"}
                            </a>
                          </div>
                          {/* <a
                        href={visa.attendant_passport1}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View File
                      </a> */}
                        </td>
                        <td>
                          <div className="report-preview">
                            {visa.attvisa1?.endsWith(".pdf") ? (
                              <embed
                                src={visa.attvisa1}
                                type="application/pdf"
                                className="pdf-preview"
                                title="PDF Preview"
                              />
                            ) : (
                              <img
                                src={visa.attvisa1}
                                alt="Image Preview"
                                className="image-preview"
                              />
                            )}
                            <a
                              href={visa.attvisa1}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="view-file-link"
                            >
                              {visa.attvisa1 !== "null"
                                ? "View Passport"
                                : "No Passport"}
                            </a>
                          </div>
                          {/* <a
                        href={visa.attvisa1}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View File
                      </a> */}
                        </td>
                      </>
                    )}
                    <td>{getHospitalNameById(visa.hospital_id, hospitals)}</td>
                    <td>{visa.country}</td>
                    <td>{visa.subject}</td>
                    <td>
                      {new Date(visa.appointment_date).toLocaleDateString()}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default VisaSubmisiionHospital;
