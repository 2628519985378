import React from "react";
import { Link } from "react-router-dom";
const ConvertedList = ({
  convertedLead,
  getHospitalNameById,
  getHospitalImageById,
  encryptId,
  hospitals,
}) => {
  return (
    <>
      {convertedLead?.length > 0 ? (
        <div className="opinion-grid">
          {convertedLead.map((opinion) => (
            <div key={opinion?.id} className="opinion-item">
              <Link
                to={`/convert-lead-detail/${encryptId(
                  opinion?.patient_id
                )}/${encryptId(opinion?.user_id)}/${encryptId(opinion?.id)}`}
              >
                <div className="image-container">
                  <img
                    src={`${process.env.REACT_APP_IMAGE_URL}/hospital/${getHospitalImageById(
                      opinion?.hospital_id,
                      hospitals
                    )}`}
                    alt="Hospital"
                    className="hospital-image"
                  />
                  <span className="hospital-name">
                    {getHospitalNameById(opinion?.hospital_id, hospitals)}
                  </span>
                </div>
              </Link>
            </div>
          ))}
        </div>
      ) : (
        <p>No uploaded opinions available.</p>
      )}
    </>
  );
};

export default ConvertedList;
