import React, { useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import LeftSlider from "../../Inc/LeftSlider";
import { decryptId, encryptId } from "../../utils/cryptoUtils";
import { useGetLeadApi } from "../../context/getAllLeadContext";
import { useWebsiteApi } from "../../context/websiteContext";

const OpinionRequestDetail = () => {
  const { pid, uid, idd } = useParams();
  const { getopinionSend, allOpinionSend } = useGetLeadApi();
  const { getHospitalNameById, getAllHospitals, hospitals, getHospitalImageById } = useWebsiteApi();
  const patinetId = decryptId(pid);
  const userId = decryptId(uid);
  const id = decryptId(idd);

  useEffect(() => {
    getopinionSend(patinetId, userId);
    getAllHospitals()
  }, [patinetId, userId]);

  const opinion = allOpinionSend?.find((item) => item.id === parseInt(id));

  return (
    <>
      <section id="home-mod">
        {/* <div className="head-top">
          <div className="head-top-left">
            <img src="https://bharatonecare.com/images/2024/01/logo-footer.png" />{" "}
          </div>
          <div className="head-top-right">
            <Link to={`/patient`}>
              {" "}
              <i className="fa  fa-angle-left"></i> Go back{" "}
            </Link>
          </div>
        </div> */}
        <div className="home-mod">
          <LeftSlider />
          <div className="home-mod-right">
            <div className="table-container">
              <h1 style={{ fontSize: "31px" }}>
                Opinion Send to{" "}
                <span style={{ color: "#ef5da2" }}>
                  {
                    opinion.description.match(
                      /<strong>HOSPITAL NAME:<\/strong> (.*?)<br>/
                    )?.[1]
                  }
                </span>{" "}
              </h1>
              <table className="opinion-table">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Patient Name</th>
                    {/* <th>Subject</th> */}
                    <th>Case History</th>
                    <th>Health Issue</th>
                    <th>Hospital Name</th>
                    <th>Created At</th>
                    {/* <th>Patient Report</th> */}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{opinion.id}</td>
                    <td>
                      {
                        opinion.description.match(
                          /<strong>NAME:<\/strong> (.*?)<br>/
                        )?.[1]
                      }
                    </td>
                    {/* <td>{opinion?.subject}</td> */}
                    <td>
                      {opinion?.case_history ? opinion?.case_history : "null"}
                    </td>
                    <td>
                      {opinion?.health_issue ? opinion?.health_issue : "null"}
                    </td>
                    <td>
                      {
                        opinion.description.match(
                          /<strong>HOSPITAL NAME:<\/strong> (.*?)<br>/
                        )?.[1]
                      }
                    </td>
                    <td>{new Date(opinion.created_at).toLocaleString()}</td>
                    {/* <td>
                      <a
                        href={
                          opinion.description.match(
                            /<strong>Patient Report:<\/strong> (.*?)<br>/
                          )?.[1]
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Report
                      </a>
                    </td> */}
                  </tr>
                </tbody>
              </table>
            </div>
            <h2
              style={{
                fontSize: "28px",
                marginTop: "20px",
                textAlign: "start",
              }}
            >
              List of all opinion Send to Hospitals
            </h2>
            {allOpinionSend?.length > 0 ? (
              <div className="opinion-grid">
                {allOpinionSend.map((opinion) => (
                  <div key={opinion?.id} className="opinion-item">
                    <Link
                      to={`/opinion-request-detail/${encryptId(
                        opinion?.patient_id
                      )}/${encryptId(opinion?.user_id)}/${encryptId(
                        opinion?.id
                      )}`}
                    >
                      <img
                        src={`${process.env.REACT_APP_IMAGE_URL}/hospital/${getHospitalImageById(
                          opinion?.hospital_id,
                          hospitals
                        )}`}
                        alt="Hospital"
                        className="hospital-image"
                      />
                      <span className="hospital-name">
                        {
                          opinion.description.match(
                            /<strong>HOSPITAL NAME:<\/strong> (.*?)<br>/
                          )?.[1]
                        }
                      </span>
                    </Link>
                  </div>
                ))}
              </div>
            ) : (
              <p>No uploaded opinions available.</p>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default OpinionRequestDetail;
