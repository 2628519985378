import React, { useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { decryptId, encryptId } from "../../utils/cryptoUtils";
import { useGetLeadApi } from "../../context/getAllLeadContext";
import LeftSlider from "../../Inc/LeftSlider";
import { useWebsiteApi } from "../../context/websiteContext";

const TicketSubmissiionDetail = () => {
  const { pid, uid, idd } = useParams();
  const { getAllSendTicket, getSendTicket } = useGetLeadApi();
  const {
    getHospitalNameById,
    getAllHospitals,
    hospitals,
    getHospitalImageById,
  } = useWebsiteApi();
  const patinetId = decryptId(pid);
  const userId = decryptId(uid);
  const id = decryptId(idd);

  useEffect(() => {
    getAllSendTicket(patinetId, userId);
    getAllHospitals();
  }, [patinetId, userId]);

  const detail = getSendTicket?.find((item) => item.id === parseInt(id));
  return (
    <>
      <section id="home-mod">
        {/* <div className="head-top">
          <div className="head-top-left">
            <img src="https://bharatonecare.com/images/2024/01/logo-footer.png" />{" "}
          </div>
          <div className="head-top-right">
            <Link to={`/patient`}>
              {" "}
              <i className="fa  fa-angle-left"></i> Go back{" "}
            </Link>
          </div>
        </div> */}
        <div className="home-mod">
          <LeftSlider />
          <div className="home-mod-right">
            <div className="table-container">
              <h1 style={{ fontSize: "31px" }}>
                Opinion Send to{" "}
                <span style={{ color: "#ef5da2" }}>
                  {getHospitalNameById(detail?.hospital_id, hospitals)}
                </span>{" "}
              </h1>
              <table className="details-table">
                <thead>
                  <tr>
                    <th>ID</th>
                    {/* <th>Patient Name</th> */}
                    <th>Visa Upload</th>
                    <th>Ticket Upload</th>
                    <th>Passport Number</th>
                    <th>Patient Passport</th>
                    {detail.attendant_name1 !== "null" && (
                      <>
                        <th>Attendant Name 1</th>
                        <th>Attendant Passport 1</th>
                      </>
                    )}
                    {detail.attendant_name2 !== "null" && (
                      <>
                        <th>Attendant Name 2</th>
                        <th>Attendant Passport 2</th>
                      </>
                    )}
                    {detail.attendant_name3 !== "null" && (
                      <>
                        <th>Attendant Name 3</th>
                        <th>Attendant Passport 3</th>
                      </>
                    )}
                    {detail.attendant_name4 !== "null" && (
                      <>
                        <th>Attendant Name 4</th>
                        <th>Attendant Passport 4</th>
                      </>
                    )}
                    <th>Embassy</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{detail.id}</td>
                    {/* <td>{leadDetail?.patient_name}</td> */}
                    <td>
                      <div className="report-preview">
                        {detail.visa_upload?.endsWith(".pdf") ? (
                          <embed
                            src={detail.visa_upload}
                            type="application/pdf"
                            className="pdf-preview"
                            title="PDF Preview"
                          />
                        ) : (
                          <img
                            src={detail.visa_upload}
                            alt="Image Preview"
                            className="image-preview"
                          />
                        )}
                        <a
                          href={detail.visa_upload}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="view-file-link"
                        >
                          {detail.visa_upload !== "null"
                            ? "View Visa"
                            : "No Visa"}
                        </a>
                      </div>
                      {/* <a
                        href={detail.visa_upload}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Patient Visa
                      </a> */}
                    </td>
                    <td>
                      <div className="report-preview">
                        {detail.ticket_upload?.endsWith(".pdf") ? (
                          <embed
                            src={detail.ticket_upload}
                            type="application/pdf"
                            className="pdf-preview"
                            title="PDF Preview"
                          />
                        ) : (
                          <img
                            src={detail.ticket_upload}
                            alt="Image Preview"
                            className="image-preview"
                          />
                        )}
                        <a
                          href={detail.ticket_upload}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="view-file-link"
                        >
                          {detail.ticket_upload !== "null"
                            ? "View Ticket"
                            : "No Ticket"}
                        </a>
                      </div>
                      {/* <a
                        href={detail.ticket_upload}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Patient Ticket
                      </a> */}
                    </td>
                    <td>{detail.passport_number}</td>
                    <td>
                      <div className="report-preview">
                        {detail.patient_passport?.endsWith(".pdf") ? (
                          <embed
                            src={detail.patient_passport}
                            type="application/pdf"
                            className="pdf-preview"
                            title="PDF Preview"
                          />
                        ) : (
                          <img
                            src={detail.patient_passport}
                            alt="Image Preview"
                            className="image-preview"
                          />
                        )}
                        <a
                          href={detail.patient_passport}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="view-file-link"
                        >
                          {detail.patient_passport !== "null"
                            ? "View Passport"
                            : "No Passport"}
                        </a>
                      </div>
                      {/* <a
                        href={detail.patient_passport}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Patient Passport
                      </a> */}
                    </td>
                    {detail.attendant_name1 !== "null" && (
                      <>
                        <td>{detail.attendant_name1}</td>
                        <td>
                          <a
                            href={detail.attendant_passport1}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View Attendant Passport 1
                          </a>
                        </td>
                      </>
                    )}
                    {detail.attendant_name2 !== "null" && (
                      <>
                        <td>{detail.attendant_name2}</td>
                        <td>
                          <a
                            href={detail.attendant_passport2}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View Attendant Passport 2
                          </a>
                        </td>
                      </>
                    )}
                    {detail.attendant_name3 !== "null" && (
                      <>
                        <td>{detail.attendant_name3}</td>
                        <td>
                          <a
                            href={detail.attendant_passport3}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View Attendant Passport 3
                          </a>
                        </td>
                      </>
                    )}
                    {detail.attendant_name4 !== "null" && (
                      <>
                        <td>{detail.attendant_name4}</td>
                        <td>
                          <a
                            href={detail.attendant_passport4}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View Attendant Passport 4
                          </a>
                        </td>
                      </>
                    )}
                    <td>{detail.embassy}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h2
              style={{
                fontSize: "28px",
                marginTop: "20px",
                textAlign: "start",
              }}
            >
              List of Ticket Submission to Hospitals
            </h2>
            {getSendTicket?.length > 0 ? (
              <div
                className="opinion-grid"
                style={{ gridTemplateColumns: "repeat(4, 1fr)" }}
              >
                {getSendTicket.map((opinion) => (
                  <div key={opinion?.id} className="opinion-item">
                    <Link
                      to={`/ticket-submission-detail/${encryptId(
                        opinion?.patient_id
                      )}/${encryptId(opinion?.user_id)}/${encryptId(
                        opinion?.id
                      )}`}
                    >
                      <div class="image-container">
                        <img
                          src={`${process.env.REACT_APP_IMAGE_URL}/hospital/${getHospitalImageById(
                            opinion?.hospital_id,
                            hospitals
                          )}`}
                          alt="Hospital"
                          className="hospital-image"
                        />
                        <span className="hospital-name">
                          {getHospitalNameById(opinion?.hospital_id, hospitals)}
                        </span>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            ) : (
              <p>No uploaded opinions available.</p>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default TicketSubmissiionDetail;
