import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useWebsiteApi } from "../../context/websiteContext";
import { decryptId, encryptId } from "../../utils/cryptoUtils";
import { usePostArrivalApi } from "../../context/getPostArrivalContext";
import LeftSlider from "../../Inc/LeftSlider";
import { Link } from "react-router-dom";

const PatientAdmissionDetail = () => {
  const { pid, uid, idd } = useParams();
  const patinetId = decryptId(pid);
  const userId = decryptId(uid);
  const id = decryptId(idd);
  const { getpatientAdmisiionList, patientAdmisList } = usePostArrivalApi();
  const {
    getHospitalNameById,
    getAllHospitals,
    hospitals,
    getHospitalImageById,
  } = useWebsiteApi();

  useEffect(() => {
    getpatientAdmisiionList(patinetId, userId);
    getAllHospitals();
  }, [patinetId, userId]);

  const detail = patientAdmisList?.find((item) => item.id === parseInt(id));
  return (
    <>
      <section id="home-mod">
        {/* <div className="head-top">
          <div className="head-top-left">
            <img src="https://bharatonecare.com/images/2024/01/logo-footer.png" />{" "}
          </div>
          <div className="head-top-right">
            <Link to={`/patient`}>
              {" "}
              <i className="fa  fa-angle-left"></i> Go back{" "}
            </Link>
          </div>
        </div> */}
        <div className="home-mod">
          <LeftSlider />
          <div className="home-mod-right">
            <div className="table-container">
              <h1 style={{ fontSize: "31px" }}>Post Admisiion Detail</h1>
              <table className="details-table">
                <thead>
                  <tr>
                    <th>ID</th>
                    {/* <th>Patient Name</th> */}
                    <th>Treatment Plan</th>
                    <th>Hospital Name</th>
                    <th>Doctor Name</th>
                    <th>Treatment Cost</th>
                    <th>Surgery Name</th>
                    <th>Stay</th>
                    <th>Discharge Date</th>
                    <th>Discharge Paper</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{detail.id}</td>
                    <td>{detail?.treatment_plan}</td>
                    <td>
                      {getHospitalNameById(detail?.hospital_id, hospitals)}
                    </td>
                    <td>{detail?.doctor_id}</td>
                    <td>$ {detail?.cost}</td>
                    <td>{detail?.surgery_name}</td>
                    <td>{detail?.stay}</td>
                    <td>{detail?.discharge_date}</td>
                    <td>
                      <div className="report-preview">
                        {detail.discharge_paper_upload?.endsWith(".pdf") ? (
                          <embed
                            src={detail.discharge_paper_upload}
                            type="application/pdf"
                            className="pdf-preview"
                            title="PDF Preview"
                          />
                        ) : (
                          <img
                            src={detail.discharge_paper_upload}
                            alt="Image Preview"
                            className="image-preview"
                          />
                        )}
                        <a
                          href={detail.discharge_paper_upload}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="view-file-link"
                        >
                          {detail.discharge_paper_upload !== "null"
                            ? "View Discharge Paer"
                            : "No data"}
                        </a>
                      </div>
                    </td>
                    <td>{detail?.description}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h2
              style={{
                fontSize: "28px",
                marginTop: "20px",
                textAlign: "start",
              }}
            >
              List of Ticket Submission to Hospitals
            </h2>
            {patientAdmisList?.length > 0 ? (
              <div
                className="opinion-grid"
                style={{ gridTemplateColumns: "repeat(4, 1fr)" }}
              >
                {patientAdmisList.map((opinion) => (
                  <div key={opinion?.id} className="opinion-item">
                    <Link
                      to={`/patient-admisiion-detail/${encryptId(
                        opinion?.patient_id
                      )}/${encryptId(opinion?.user_id)}/${encryptId(
                        opinion?.id
                      )}`}
                    >
                      <div className="image-container">
                        <img
                          src={`${process.env.REACT_APP_IMAGE_URL}/hospital/${getHospitalImageById(
                            opinion?.hospital_id,
                            hospitals
                          )}`}
                          alt="Hospital"
                          className="hospital-image"
                        />
                        <span className="hospital-name">
                          {getHospitalNameById(opinion?.hospital_id, hospitals)}
                        </span>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            ) : (
              <p>No uploaded opinions available.</p>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default PatientAdmissionDetail;
