import React, { useState, useEffect } from "react";
import LeftSlider from "../../Inc/LeftSlider";
import "../upload-passport/uploadPassport.css";
import { useParams } from "react-router-dom";
import { decryptId } from "../../utils/cryptoUtils";
import DatePicker from "react-datepicker";
import { axiosInstance } from "../../axiosInstance/axiosInstance";
import Select from "react-select";
import { useWebsiteApi } from "../../context/websiteContext";

const UploadVisa = () => {
  const { userIdE, patientIdE, specE } = useParams();
  const userId = decryptId(userIdE);
  const patientId = decryptId(patientIdE);
  const spec = decryptId(specE);

  const [loading, setLoading] = useState(false);
  const { getAllHospitals, hospitals } = useWebsiteApi();
  const [selectedHospitals, setSelectedHospitals] = useState([]);

  useEffect(() => {
    getAllHospitals();
  }, []);
  const handlehospitalChange = (selectedOptions) => {
    setSelectedHospitals(selectedOptions);
  };

  const [formData, setFormData] = useState({
    patient_passport: null,
    embassy_file: null,
    country: "",
    passport_number: "",
    doctor_name: "",
    attendant_name1: "",
    att_passport_number1: "",
    attendant_name2: "",
    att_passport_number2: "",
    attendant_name3: "",
    att_passport_number3: "",
    attendant_name4: "",
    att_passport_number4: "",
    attendant_passport1: null,
    attendant_passport2: null,
    attendant_passport3: null,
    attendant_passport4: null,
    pvisa: null,
    attvisa1: null,
    attvisa2: null,
    attvisa3: null,
    attvisa4: null,
    appointment_date: "",
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (files && files[0]) {
      const file = files[0];
      const fileType = file.type;

      if (fileType !== "application/pdf") {
        alert("Only PDF files are allowed.");
        return;
      }

      setFormData((prevData) => ({
        ...prevData,
        [name]: file,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleDateChange = (date, name) => {
    setFormData((prev) => ({
      ...prev,
      [name]: date,
    }));
  };

  const validateForm = () => {
    const {
      passport_number,
      patient_passport,
      country,
      pvisa,
      appointment_date,
    } = formData;

    if (!passport_number) return "Patinet Passport Number is required.";
    if (!patient_passport)
      return "Please upload Patient Paaport in  a PDF file.";
    if (!country) return "Country is required.";
    if (!pvisa) return "Please upload Patient Visa in  a PDF file.";
    if (!appointment_date) return "Please Select Appoinment Date.";

    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationError = validateForm();
    if (validationError) {
      alert(validationError);
      return;
    }

    if (selectedHospitals.length > 0) {
      const hospitalDetails = selectedHospitals.map((selectedHospital) => {
        const fullDetails = hospitals.find(
          (hospital) => hospital.id === selectedHospital.value
        );
        return {
          id: fullDetails.id,
          name: fullDetails.name,
          hospital_email: fullDetails.hospital_email,
          hospital_phone: fullDetails.hospital_phone,
        };
      });
      const formDataToSend = new FormData();

      // Append form fields to FormData
      for (const key in formData) {
        if (formData[key]) {
          formDataToSend.append(key, formData[key]);
        } else {
          formDataToSend.append(key, null);
        }
      }
      formDataToSend.append("hospitals", JSON.stringify(hospitalDetails));
      formDataToSend.append("patient_id", patientId);
      formDataToSend.append("user_id", userId);
      formDataToSend.append("speciality_id", spec);

      setLoading(true);
      try {
        const response = await axiosInstance.post(
          "/pre_intimation_arrival",
          formDataToSend,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        alert("Visa Send to hospital Successfully");
        setFormData({
          patient_passport: null,
          embassy_file: null,
          country: "",
          passport_number: "",
          doctor_name: "",
          attendant_name1: "",
          att_passport_number1: "",
          attendant_name2: "",
          att_passport_number2: "",
          attendant_name3: "",
          att_passport_number3: "",
          attendant_name4: "",
          att_passport_number4: "",
          attendant_passport1: null,
          attendant_passport2: null,
          attendant_passport3: null,
          attendant_passport4: null,
          pvisa: null,
          attvisa1: null,
          attvisa2: null,
          attvisa3: null,
          attvisa4: null,
          appointment_date: "",
        });
      } catch (error) {
        console.error("Error uploading visa form", error);
      } finally {
        setLoading(false);
      }
    } else {
      alert("No hospitals selected.");
      setLoading(false);
    }
  };

  const hospitalOptions = hospitals.map((hospital) => ({
    value: hospital.id,
    label: hospital.name,
  }));

  return (
    <>
      {loading && (
        <div className="loading-container">
          <div className="loading-spinner"></div>
        </div>
      )}
      <section id="home-mod">
        <div className="home-mod">
          <LeftSlider />
          <div className="home-mod-right">
            <div className="scrollbar-1">
              <div className="query-form-page">
                <h2>Visa Submission to Hospital</h2>
                <div className="visa-form">
                  <div className="visa-form-box">
                    <label>Select Hospital</label>
                    <Select
                      isMulti
                      name="hospitals"
                      options={hospitalOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      value={selectedHospitals}
                      onChange={handlehospitalChange}
                    />
                  </div>
                  <div className="visa-form-box">
                    <label>Doctor Name</label>
                    <input
                      type="text"
                      name="doctor_name"
                      onChange={handleChange}
                    />
                  </div>

                  <div className="visa-form-box">
                    <label>Patient Visa</label>
                    <input type="file" name="pvisa" onChange={handleChange} />
                  </div>

                  <div className="visa-form-box">
                    <label>Patient Passport Number</label>
                    <input
                      type="text"
                      name="passport_number"
                      onChange={handleChange}
                    />
                  </div>

                  <div className="visa-form-box">
                    <label>Patient Passport</label>
                    <input
                      type="file"
                      name="patient_passport"
                      onChange={handleChange}
                    />
                  </div>

                  <div className="visa-form-box">
                    <label>Attendant Name 1</label>
                    <input
                      type="text"
                      name="attendant_name1"
                      onChange={handleChange}
                    />
                  </div>

                  <div className="visa-form-box">
                    <label>Attendant Visa 1</label>
                    <input
                      type="file"
                      name="attvisa1"
                      onChange={handleChange}
                    />
                  </div>

                  <div className="visa-form-box">
                    <label>Attendant Passport Number 1</label>
                    <input
                      type="text"
                      name="att_passport_number1"
                      onChange={handleChange}
                    />
                  </div>

                  <div className="visa-form-box">
                    <label>Attendant Passport 1</label>
                    <input
                      type="file"
                      name="attendant_passport1"
                      onChange={handleChange}
                    />
                  </div>

                  <div className="visa-form-box">
                    <label>Attendant Name 2</label>
                    <input
                      type="text"
                      name="attendant_name2"
                      onChange={handleChange}
                    />
                  </div>

                  <div className="visa-form-box">
                    <label>Attendant Visa 2</label>
                    <input
                      type="file"
                      name="attvisa2"
                      onChange={handleChange}
                    />
                  </div>

                  <div className="visa-form-box">
                    <label>Attendant Passport Number 2</label>
                    <input
                      type="text"
                      name="att_passport_number2"
                      onChange={handleChange}
                    />
                  </div>

                  <div className="visa-form-box">
                    <label>Attendant Passport 2</label>
                    <input
                      type="file"
                      name="attendant_passport2"
                      onChange={handleChange}
                    />
                  </div>

                  <div className="visa-form-box">
                    <label>Attendant Name 3</label>
                    <input
                      type="text"
                      name="attendant_name3"
                      onChange={handleChange}
                    />
                  </div>

                  <div className="visa-form-box">
                    <label>Attendant Visa 3</label>
                    <input
                      type="file"
                      name="attvisa3"
                      onChange={handleChange}
                    />
                  </div>

                  <div className="visa-form-box">
                    <label>Attendant Passport Number 3</label>
                    <input
                      type="text"
                      name="att_passport_number3"
                      onChange={handleChange}
                    />
                  </div>

                  <div className="visa-form-box">
                    <label>Attendant Passport 3</label>
                    <input
                      type="file"
                      name="attendant_passport3"
                      onChange={handleChange}
                    />
                  </div>

                  <div className="visa-form-box">
                    <label>Attendant Name 4</label>
                    <input
                      type="text"
                      name="attendant_name4"
                      onChange={handleChange}
                    />
                  </div>

                  <div className="visa-form-box">
                    <label>Attendant Visa 4</label>
                    <input
                      type="file"
                      name="attvisa4"
                      onChange={handleChange}
                    />
                  </div>

                  <div className="visa-form-box">
                    <label>Attendant Passport Number 4</label>
                    <input
                      type="text"
                      name="att_passport_number4"
                      onChange={handleChange}
                    />
                  </div>

                  <div className="visa-form-box">
                    <label>Attendant Passport 4</label>
                    <input
                      type="file"
                      name="attendant_passport4"
                      onChange={handleChange}
                    />
                  </div>

                  <div className="visa-form-box">
                    <label>Country</label>
                    <input
                      type="text"
                      name="country"
                      value={formData.country}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="visa-form-box">
                    <label>Appointment Date</label>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      selected={formData.appointment_date}
                      onChange={(date) =>
                        handleDateChange(date, "appointment_date")
                      }
                      className="date-picker-input"
                      placeholderText="Select document submission date"
                    />
                  </div>
                  <div className="visa-form-box" onClick={handleSubmit}>
                    <button type="submit" name="en" className="visa-submit">
                      {" "}
                      Submit Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UploadVisa;
