import React, { useEffect } from "react";
import "../patientDetails/patientDetail.css";
import LeftSlider from "../../Inc/LeftSlider";
import WhatsAppTemplateList from "../patientDetails/WhatsAppTemplateList";
import { useParams, useNavigate, Link } from "react-router-dom";
import { decryptId } from "../../utils/cryptoUtils";
import { useGetLeadApi } from "../../context/getAllLeadContext";
import ShowTemplateSend from "./ShowTemplateSend";

const WhatsappTemplate = () => {
  const { did } = useParams();
  const navigate = useNavigate();
  const id = decryptId(did);
  const {
    leadDetail,
    getLeadbyId,
    patienLeadDetail,
    userLeadDetail,
    patientSpeciality,
    patientJourney,
    getWhatsappTemplateList,
    templateSend,
  } = useGetLeadApi();

  const patinetId = patienLeadDetail?.id;
  const userId = userLeadDetail[0]?.id;
  const patientJourneyId = patientJourney?.id;

  useEffect(() => {
    getLeadbyId(id);
  }, [id, patientJourneyId, patinetId, userId]);

  const formatDate = (isoString) => {
    const date = new Date(isoString);

    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    const hours = date.getHours();
    const minutes = date.getMinutes();
    const period = hours >= 12 ? "PM" : "AM";

    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, "0");

    return `${day}${getDaySuffix(
      day
    )} ${month} ${year} <span></span> ${formattedHours}:${formattedMinutes} ${period}`;
  };
  const getDaySuffix = (day) => {
    if (day > 3 && day < 21) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };
  return (
    <>
      <section id="home-mod">
        <div className="head-top">
          <div className="head-top-left">
            {" "}
            <img src="https://bharatonecare.com/images/2024/01/logo-footer.png" />{" "}
          </div>
          <div className="head-top-right">
            <Link to={`/patient/${did}`}>
              {" "}
              <i className="fa  fa-angle-left"></i> Go back{" "}
            </Link>
          </div>
        </div>
        <div className="home-mod">
          <LeftSlider />
          <div className="home-mod-right">
            <div className="scrollbar-1">
              <div className="patient-dashboard-doc">
                <div className="patient-dashboard-box">
                  <div className="patient-img-box">
              
                    <img src="/images/userO.png" />
                  </div>
                  <div className="patient-doc-box">
                    <h2
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {leadDetail?.patient_name}
                    </h2>
                    <div className="patient-doc-data">
                      <ul>
                        {/* <li>
                          <img src="/images/2024/01/03/1.png" /> Female
                        </li>
                        <li>
                          <img src="/images/2024/01/03/2.png" /> India
                        </li> */}
                        <li>
                          <img src="/images/2024/01/03/3.png" />{" "}
                          {patientSpeciality?.name}
                        </li>
                        <li>
                          <img src="/images/2024/01/03/4.png" />{" "}
                          {leadDetail?.patient_phone}
                        </li>
                        <li>
                          <img src="/images/2024/01/03/4.png" />{" "}
                          {leadDetail?.patient_email}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="patient-dashboard-query">
                  <h3>Query</h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: formatDate(leadDetail?.created_at),
                    }}
                  />
                </div>
                <div className="patient-dashboard-reply">
                  <h3>Source</h3>
                  <p>
                    {leadDetail.platform ? leadDetail.platform : "Manually Add"}
                  </p>
                </div>
              </div>
              <div className="patient-dashboard-casebox">
                <ShowTemplateSend
                  userId={userId}
                  patientId={patinetId}
                  formatDate={formatDate}
                  getWhatsappTemplateList={getWhatsappTemplateList}
                  templateSend={templateSend}
                />
                <div className="patient-dashboard-case-right">
                  <div className="patient-dashboard-reports">
                    <h2>Select Template to Send</h2>
                    <WhatsAppTemplateList
                      userId={userId}
                      patientId={patinetId}
                      getWhatsappTemplateList={getWhatsappTemplateList}
                      leadPhoneNumber={patienLeadDetail?.phone}
                      leadName={leadDetail?.patient_name}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhatsappTemplate;
